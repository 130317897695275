import { MagicUseCase } from '~/use-cases/magicUseCase';

export class SetCurrentLanguageUseCase extends MagicUseCase {
  protected async runLogic(currentLanguage: string) {
    const state = this.getState();
    if (state.user.companyInfo) {
      state.user.companyInfo.currentLanguage = currentLanguage;
    }
  }
}
